<div *ngIf="club">
  <div class="club-header">
    <div class=" container">
      <span class="gap"></span>
      <div class="club-header-section">
        <div class="club-header-text">
          <h1 [innerHTML]="club.fields.title"></h1>
          <span class="gap"></span>
          <p class="about-statement" [innerHTML]="club.fields.missionabout|mdToHtml"></p>
          <span class="gap"></span>
          <a mat-fab extended color="primary" target="_blank"
            [href]="'https://'+club.fields.membershipDocument.fields.file.url"
            *ngIf="club.fields.membershipDocument">Application to Join Our {{club.fields.hasAwards?'Award Winning':''}} Club</a>
        </div>
        <img class="club-photo" [src]="club.fields.image.fields.file.url" />
      </div>
    </div>
  </div>
  <div class="awards" *ngIf="club.fields.achievements">
    <h3>Awards</h3>
    <p *ngFor="let a of club.fields.achievements">{{a}}</p>
  </div>
  <div class="container">
    <div class="club-event" *ngFor="let events of club.fields.events">
      <img class="club-event-image" [src]="events.fields.image.fields.file.url"
        [alt]="events.fields.image.fields.title" />

      <article>
        <h2 class="club-event-title">{{events.fields.title}}</h2>
        <div class="club-event-date" [innerHTML]="events.fields.dateTime| date: 'fullDate'"></div>
        <span class="gap"></span>
        <div class="club-event-venue" [innerHTML]="events.fields.venue"></div>
        <span class="gap"></span>
        <address [innerHTML]="events.fields.address"></address>
        <span class="gap"></span>
        <button mat-fab extended [routerLink]="['/events',events.fields.url]">Read More</button>
      </article>
    </div>

    <!-- Resources -->
    <div *ngIf="club.fields.resources">
      <h2>Resources</h2>
      <br>
      <div class="resources">
        <div class="resource" *ngFor="let resource of club.fields.resources">
          <a target="_blank" [href]="'https://'+resource.fields.file.url">

            <div class="resource-text">
              <div><mat-icon class="material-icons-round resource-icon">text_snippet</mat-icon></div>
              <span class="gap"></span>
              <div class="resource-title">
                {{resource.fields.title}}
              </div>
              <span class="gap"></span>
              <div align="end">
                <a target="_blank" mat-fab extended [href]="'https://'+resource.fields.file.url"
                  color="primary">Download</a>
              </div>
            </div>
          </a>

        </div>
      </div>
    </div>


    <!-- Newsletters -->
    <div *ngIf="club.fields.newsletter">
      <h2>Newsletters</h2>
      <div class="newsletters">
        <div class="newsletter" *ngFor="let newsletter of club.fields.newsletter| slice: 0:3">
          <div class="newsletter-title" [innerHTML]="newsletter.fields.title"></div>
          <br>
          <span class="gap"></span>
          <a mat-fab extended [href]="'https:'+newsletter.fields.file.url" target="_blank" color="primary"><mat-icon
              class="material-icons-round">newspaper</mat-icon> Read Newsletter</a>
        </div>
      </div>
    </div>

    <span class="gap"></span>
    <span class="gap"></span>
    <!-- Club Photos -->
    <h2 *ngIf="club.fields.clubPhotos">Photos From {{club.fields.abbreviation}} Events</h2>
    <span class="gap"></span>
    <div class="club-photos">
      <a *ngFor="let photos of club.fields.clubPhotos" target="_blank" [href]="'https:'+photos.fields.file.url">
        <img [src]="photos.fields.file.url" [alt]="photos.fields.title">
      </a>

    </div>

    <span class="gap"></span>
    <span class="gap"></span>
    <!-- Socials -->
    <h2>Follow Us on</h2>
    <span class="gap"></span>
    <span class="gap"></span>
    <div class="socials">
      <a *ngIf="club.fields.instagram" [href]="club.fields.instagram">
        <i class="fa-brands fa-instagram"></i>
      </a>
      <a *ngIf="club.fields.twitter" [href]="club.fields.twitter">
        <i class="fa-brands fa-twitter"></i>
      </a>
      <a *ngIf="club.fields.facebook" [href]="club.fields.facebook">
        <i class="fa-brands fa-facebook-f"></i>
      </a>
      <a *ngIf="club.fields.youtube" [href]="club.fields.youtube">
        <i class="fa-brands fa-youtube"></i>
      </a>
    </div>
  </div>


</div>